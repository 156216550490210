/* Loading indicator */
.loader {
  width: 100px;
  height: 100px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Error Label */
.agx-error-label {
  display: flex;
  align-items: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;

  letter-spacing: 0.06px;

  color: #444444;
}
.agx-error-label > label {
  padding-left: 4px;
}

.disclaimerText.ui-caption {
  text-align: center;
  color: var(--brand-text-primary-color);
  opacity: 0.75;
}

.agx-select.error > .errorMessage {
  position: relative;
  margin: 0;
}

.rpdataDisclaimerLabel.label {
  color: var(--brand-text-primary-color);

  .showHideDisclaimer {
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.75;
    color: var(--brand-text-primary-color);
  }
}
