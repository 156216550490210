@import '../../../../../../../Mixins.scss';

.contractListingDetails {
  justify-content: start;
  flex: 1;

  @include desktop {
    > div > img {
      max-width: 320px;
    }

    > button {
      min-width: 300px;
    }
  }
}

.listing-imageCard {
  @include desktop {
    padding: 28px;
    width: 320px !important;
    height: 160px !important;
  }

  @include tabletLandscape {
    padding: 28px;
    width: 320px !important;
    height: 160px !important;
  }

  padding: 12px;

  background: rgba(255, 255, 255, 0.9);
  border-radius: 18px;
}

.imageCard-image {
  &.archived {
    filter: grayscale(100%);
  }

  @include desktop {
    border-radius: 10px;
    width: -webkit-fill-available;
    width: -moz-available;
    aspect-ratio: 2 / 1;
  }
}
