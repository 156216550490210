#downloadContracts {
  align-self: center;
}

.signatoriesContainer {
  .divider {
    margin-left: -20px;
    margin-right: -20px;
  }
}
