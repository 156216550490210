@import '../../../../../../Mixins.scss';
@import '../../../../../../App.scss';

.imageCard {
  padding: 8px;
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.05),
    0px 1px 2px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 18px;
  border-radius: 20px;
  transition: 0.3s;
  gap: 20px;
  width: -webkit-fill-available;
  width: -moz-available;

  @include mobile {
    gap: 12px;
    max-width: 440px;
  }

  @include tabletPortrait {
    max-width: 440px;
  }

  @include tabletLandscape {
    max-width: 440px;
  }

  @include desktop {
    max-width: 800px;
  }

  .propertyImageCard-Details {
    gap: 14px;
    padding: 8px 0px;

    .propertyImageCard-Details-TopRow {
      > .agx-button {
        align-self: baseline;
      }
    }

    .propertyImageCard-Details-Title {
      gap: 3px;
    }
  }

  .imageCard-mobile-details {
    gap: 14px;
    padding-bottom: 8px;

    .imageCard-mobile-details-header {
      gap: 3px;

      > h4.header {
        font-size: 18px;
      }
    }
  }
}

.imageCard:hover {
  cursor: pointer;
  background: rgba(245, 245, 245, 1);

  svg,
  label {
    cursor: pointer;
  }

  .hoverConfirmationBtn {
    background: linear-gradient(
        180deg,
        var(--brand-secondary-color-alpha90) 0,
        var(--brand-secondary-color) 72.9%
      )
      var(--brand-secondary-color);
    border: none;
    color: var(--brand-text-button-color);

    > svg {
      fill: var(--brand-text-button-color);
    }
  }
}

.imageCardImage {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 20px 20px 0px 0px;
}

.imageCardContainer {
  padding: 8px 16px;
}

.imageCard-image {
  border-radius: 10px;
  min-width: 200px;

  @include desktop {
    width: fit-content;
  }

  width: -webkit-fill-available;
  width: -moz-available;

  aspect-ratio: 2 / 1;
}

.propertyImageLabel.label {
  color: var(--neutral-grey-600);
  display: flex;
  align-items: center;
  justify-content: center;
}

.propertyImageDetail {
  font-weight: 400;
}

.propertyDetailsRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  &.firstRow {
    @include mobile {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 18px 0;
    }
  }
}

.mappedDetailsRowSpacing {
  gap: 12px 24px;

  &.propertyNumbers {
    gap: 18px 36px;

    &.row {
      align-items: baseline;
    }

    @include mobile {
      &:not(.propertyOwners) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    @include tabletPortrait {
      &:not(.propertyOwners) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    @include tabletLandscape {
      &:not(.propertyOwners) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    @include mobile {
      gap: 10px 20px;
    }
  }

  .mapDetailsColumnItem {
    align-items: baseline;
  }
}

.mappedDetailsItem {
  display: flex;
  align-items: flex-end;
  gap: 4px;
}
