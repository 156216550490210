@import '../../../Mixins.scss';

.marketingPackageSelector {
  padding: 0 0 32px 0;
}

.agentToFillCurrency {
  width: 110px;
  &.error {
    padding: 6px 0;
  }
}

.customItemLabel {
  &.error {
    padding-bottom: 0;
  }
  .wrapper {
    padding: 5px 8px;
  }
  .error {
    padding: 5px 8px;
  }
}

.customItemPrice {
  width: 150px;

  @include mobile {
    width: 220px;
  }
}
