@import '../../../../../../Mixins.scss';
@import '../../../../../../App.scss';

.closeButton + .content .formDetailsHeaderMobile {
  @include mobile {
    margin-top: 0;
  }
}

.formDetailsHeaderMobile.row {
  @include desktop {
    display: none !important;
  }

  @include tabletLandscape {
    display: none !important;
  }

  .archive {
    width: 20px;
  }

  .restore {
    width: 20px;
  }

  .formDetailsBack {
    width: 20px;
  }

  .formDetailsTitleInfo {
    text-align: center;
  }

  .formDetailsTitleLastUpdated {
    margin: auto;
  }
}

.formDetailsHeaderDesktop.row {
  @include mobile {
    display: none;
  }

  @include tabletPortrait {
    display: none;
  }

  .archive {
    width: 100px;
  }

  .restore {
    width: 100px;
  }

  .formDetailsArchiveButton {
    color: var(--neutral-grey-600);
  }

  .formDetailsTitleInfo {
    &:has(.formDetailsBack):hover {
      cursor: pointer;
    }
  }
}

.formDetailsBack {
  @include mobile {
    position: absolute;
    top: 0;
    left: 22px;
  }

  > svg {
    width: 18px;
    height: 18px;
    fill: var(--neutral-grey-600);
  }
}

.formDetailsTitle {
  text-align: center;
}
